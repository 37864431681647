module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-theme-ui-virtual-82a3ae5344/0/cache/gatsby-plugin-theme-ui-npm-0.3.5-4e5dcb2c2f-6c32b144f9.zip/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-gtag-virtual-4cbcd8aade/0/cache/gatsby-plugin-gtag-npm-1.0.13-387068c71b-f3ac5be360.zip/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-54Y96HW76G","head":true},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-ffaa737508/0/cache/gatsby-remark-images-npm-3.11.1-7e0015fdca-5490ef5455.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"loading":"lazy"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-8216caac5e/0/cache/gatsby-remark-autolink-headers-npm-2.11.0-57636de557-2bbefdecaa.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-99d91ea672/0/cache/gatsby-plugin-manifest-npm-2.12.1-284866089d-d9c0dc517a.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hoon's Blog","short_name":"Hoon's Blog","description":"Hoon's Backend Dev Blog","start_url":"/","background_color":"#fff","theme_color":"#6a737d","theme_color_in_head":false,"lang":"ko-KR","display":"standalone","icon":"src/images/icon.png","legacy":false,"include_favicon":false,"crossOrigin":"use-credentials","cache_busting_mode":"query","cacheDigest":"4b287676c17549d67d4cef802131751c"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-typography-virtual-d9be9fc45f/0/cache/gatsby-plugin-typography-npm-2.12.0-1324b8c9eb-0811e00e4a.zip/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.ts"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-preact-virtual-53e37926f6/0/cache/gatsby-plugin-preact-npm-4.7.0-50b1af4049-222b5caf33.zip/node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
